import React from 'react';
import Layout from '../components/layout';
import Img from 'gatsby-image';
import Metatags from '../components/Metatags';
import { graphql } from 'gatsby'
function BlogPost(props) {
  const post = props.data.markdownRemark;
  const url = props.data.site.siteMetadata.siteUrl;
  const { title, description, author, date } = post.frontmatter;
  const thumbnail =  post.frontmatter.image ? post.frontmatter.image.childImageSharp.resize.src : null;

  let image;
  if (post.frontmatter.image) {
    image = <Img fluid={post.frontmatter.image.childImageSharp.fluid} />;
  }
  return (
    <Layout>
      <Metatags
        type="NewsArticle"
        title={title}
        description={description}
        thumbnail={url + thumbnail}
        url={url}
        pathname={props.location.pathname}
        author={author}
        date={date}
      />
      <div>
        <h1>{title}</h1>
        {image}
        <div dangerouslySetInnerHTML={{ __html: post.html }} />
      </div>
    </Layout>
  )
}

export default BlogPost

export const query = graphql`
 query PostQuery($slug: String!) {
     markdownRemark(fields: { slug: { eq: $slug } }) {
       html
       frontmatter {
        title
        description
        author
        date
        image {
          childImageSharp {
            resize(width: 1500, height: 1500) {
              src
            }
            fluid(maxWidth: 786) {
              ...GatsbyImageSharpFluid
            }
          }
       }
       }
   }
  site {
    siteMetadata {
        siteUrl
      }
   }
}
`
